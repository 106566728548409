
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - #{$header-height});
  text-align: center;

  img {
    // prettier-ignore
    @include fluid(width, (xxs: 50px, xxl: 100px));

    display: inline-block;
  }

  @include mq(m) {
    height: calc(100vh - #{$header-height-large});
  }
}
